import { Component, OnInit, Input } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';
import { ParkService } from 'src/app/park.service';

@Component({
  selector: 'app-np-grid',
  templateUrl: './np-grid.component.html',
  styleUrls: ['./np-grid.component.scss'],
  animations: [
    trigger('dimZoom', [
      state('normal', style({
        transform: 'scale(1)',
        filter: 'brightness(100%)'
      })),
      state('zoomed', style({
        transform: 'scale(1.2)',
        filter: 'brightness(50%)'
      })),
      transition('normal <=> zoomed', animate(250))
    ]),
    trigger('shown', [
      state('normal', style({
        opacity: 0
      })),
      state('zoomed', style({
        opacity: 1
      })),
      transition('normal <=> zoomed', animate(250))
    ])
  ]
})
export class NpGridComponent implements OnInit {
  @Input() parkUrl: string;

  parkNameCh: string;
  parkNameEn: string;
  npsUrl: string;
  thumbnail: string;

  imgState: string;
  btnState: string;

  constructor(private parkService: ParkService) {}

  ngOnInit() {
    this.parkNameCh = this.parkService.getPark(this.parkUrl).getNameCh();
    this.parkNameEn = this.parkService.getPark(this.parkUrl).getNameEn();
    this.npsUrl = this.parkService.getPark(this.parkUrl).getNpsUrl();
    this.thumbnail = this.parkService.getPark(this.parkUrl).getThumbnail();

    this.imgState = 'normal';
    this.btnState = 'normal';
  }

  mouseEnter() {
    this.imgState = 'zoomed';
    this.btnState = 'zoomed';
  }

  mouseLeave() {
    this.imgState = 'normal';
    this.btnState = 'normal';
  }

}
