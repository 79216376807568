import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ParkService } from 'src/app/park.service';

@Component({
  selector: 'app-np-content',
  templateUrl: './np-content.component.html',
  styleUrls: ['./np-content.component.scss']
})
export class NpContentComponent implements OnInit {
  parkUrl: string;
  npsUrl: string;
  videoId: string[];

  constructor(private parkService: ParkService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.parkUrl = this.route.snapshot.params['name'];
    this.npsUrl = this.parkService.getPark(this.parkUrl).getNpsUrl();
    this.videoId = this.parkService.getPark(this.parkUrl).getVideoId();
  }

}
