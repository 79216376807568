<div class="bg">
  <div class="center">
    <h5 class="name">
      <strong>
        黃柏愷
      </strong>
    </h5>
    <h5 class="subtitle">
      <strong>旅遊Vlogger & 攝影師</strong>
    </h5>
  </div>
</div>
<div class="container padding-top">
  <div class="row">
    <div class="col-lg-7 about-me">
      <h2>關於我</h2>
      <p>我是一個住在鹽湖城的工程師</p>
    </div>
    <div class="col-lg-5">
      <img src="/assets/img/myself.jpg" class="img-fluid" alt="Responsive image">
    </div>
  </div>
  <div class="row">
    <div class="col-lg-3">
      <h2>聯絡方式</h2>
    </div>
    <div class="col-lg-9">
      <a class="contact-info black" href="https://www.youtube.com/channel/UCF0isvgYaaTVXBtUihQNiUQ" target="_blank">
        <i class="fa fa-youtube fa-2x" aria-hidden="true"></i>
      </a>
      <a class="contact-info black" href="https://www.instagram.com/travel_kevin_bkh/" target="_blank">
        <i class="fa fa-instagram fa-2x" aria-hidden="true"></i>
      </a>
      <span class="contact-info email">bkhkevin@gmail.com</span>
    </div>
  </div>
</div>
