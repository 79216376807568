<div class="card border-0" style="margin: 3px;">
    <div 
        class="img-zoom shadow"
        (mouseenter) ="mouseEnter()"
        (mouseleave) ="mouseLeave()">
        <img
            class="card-img-top"
            [src]="thumbnail"
            [@dimZoom]="imgState"
            alt="Card image cap">
        <a class="btn upper-btn btn-hollow"
            role="button"
            [@shown]="btnState"
            [routerLink]="['/us-national-parks', parkUrl]">
            前往部落格
        </a>
        <a class="btn lower-btn btn-hollow"
            role="button"
            [@shown]="btnState"
            [href]="npsUrl"
            target="_blank">
            前往官方網站
        </a>
    </div>
    <div class="card-body">
        <h5>{{ parkNameCh }}</h5>
        <h6>{{ parkNameEn }}</h6>
    </div>
</div>
