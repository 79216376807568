<div class="container">
  <div class="background"></div>
  <div class="foreground"></div>
  <div class="center">
    <h5 class="name">旅遊凱文</h5>
    <h5 class="subtitle">Travel Kevin BKH</h5>
  </div>
</div>
<div class="avatar">
  <img src="/assets/img/my-big-head-square.png"/>
</div>
<div class="container padding-top">
  <h2>現在什麼內容都還沒有，敬請包涵。</h2>
  <h2>你可以先查看美國國家公園的列表，我的目標是走訪每一個國家公園！</h2>
  <div class="row">
    <div class="col-lg-4">
      <h3>國家公園</h3>
      <p>美國國土遼闊，從東岸到西岸自然風景各不同。在境內成立的國家公園管理局來管理與保護珍貴的自然奇景。各個公園都獨具特色、美不勝收。如果你喜歡，請跟我一起走訪每個國家公園。</p>
      <button class="btn btn-4" [routerLink]="['/us-national-parks']">美國自然奇觀 &rarr;</button>
    </div>
    <div class="col-lg-4">
      <h3>世界旅遊</h3>
      <p>世界何其大，人一生能踏足多少土地？跳上飛機前往與家鄉不同的國度，欣賞各地的不同，總是讓我對世界更好奇。不需要等生活壓力大才需要一趟旅行，跟我一起將旅行變成生活的一部分。</p>
      <button class="btn btn-4" [routerLink]="['/world-travel']">好奇心無國界 &rarr;</button>
    </div>
    <div class="col-lg-4">
      <h3>攝影作品</h3>
      <p>手中握有一台Nikon D750，背起巨大沈重的機身，我在所到之處紀錄各個美麗的景色。一張照片怎樣才叫美？我相信因人而異，但我只分享我覺得滿意的作品，希望你也會喜歡。</p>
      <button class="btn btn-4" [routerLink]="['/photography']">隨手單眼快照 &rarr;</button>
    </div>
  </div>
</div>
