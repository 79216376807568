<nav class="navbar navbar-expand-lg navbar-light fixed-top" scroller>
  <a class="navbar-brand" 
    [ngClass]="{'white-color': whiteLink}"
    [routerLink]="['/']">{{ name }}</a>
  <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="toggleNavbar()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div
    class="collapse navbar-collapse"
    [ngClass]="{show: navbarOpen}"
    id="navbarNav">
    <ul class="navbar-nav">
      <li class="nav-item" *ngFor="let category of categories; let i = index">
        <a
          class="nav-link"
          [ngClass]="{'white-color': whiteLink}"
          [routerLink]="categoriesLink[i]"
          (click)="closeNavbar()"
        >{{ category }}</a>
      </li>
    </ul>
  </div>
</nav>
<button class="scroll-to-top" (click)="scrollToTop()" scroll-to-top>回到頂部</button>
