import * as usParks from '../assets/data/us-parks.json';
import * as usStates from '../assets/data/us-states.json';
import * as usRegions from '../assets/data/us-regions.json';
import { Injectable } from "@angular/core";

export enum MenuStyle {
  GRID,
  TABLE,
}

export enum MenuSort {
  VISITED,
  ESTABLISHED,
  NAME,
  STATE,
  REGION,
}

export enum MenuSortOrder {
  DEFAULT,
  REVERSE,
}

@Injectable()
export class ParkService {
  usParks: Map<string, Park>;

  constructor() {
    this.usParks = new Map<string, Park>();
    let parks = usParks['default'];
    let sortedVisitedOrder = this.sortVisitedTime(parks);
    for (var park in parks) {
      let parkDetails = new Park(
        park,
        parks[park]['ch'],
        parks[park]['en'],
        parks[park]['states'],
        parks[park]['nps_url'],
        parks[park]['region'],
        parks[park]['visited'],
        parks[park]['established_time'],
        parks[park]['visited_time'],
        parks[park]['established_order'],
        parks[park]['video_id'],
        parks[park]['geoloc']['lat'],
        parks[park]['geoloc']['lon'],
        sortedVisitedOrder.get(park),
      );
      this.usParks.set(park, parkDetails);
    }
  }

  getLength() {
    return this.usParks.size;
  }

  getPark(park: string) {
    return this.usParks.get(park);
  }

  getParkUrls() {
    return [...this.usParks.keys()];
  }

  getParks(sort: MenuSort, sortOrder: MenuSortOrder) {
    function byEnName(a: Park, b: Park) {
      return a.getParkUrl() < b.getParkUrl() ? -1 : 1;
    }
    function byVisited(a: Park, b: Park) {
      var aOrder = a.getVisitedOrder();
      var bOrder = b.getVisitedOrder();
      if (aOrder != 0 && bOrder != 0) {
        return aOrder - bOrder;
      } else if (aOrder == 0 && bOrder == 0) {
        return byEnName(a, b);
      } else {
        return aOrder != 0 ? -1 : 1;
      }
    }
    function byVisitedReverse(a: Park, b: Park) {
      var aOrder = a.getVisitedOrder();
      var bOrder = b.getVisitedOrder();
      if (aOrder != 0 && bOrder != 0) {
        return bOrder - aOrder;
      } else if (aOrder == 0 && bOrder == 0) {
        return byEnName(a, b);
      } else {
        return aOrder != 0 ? -1 : 1;
      }
    }
    function byState(a: Park, b: Park) {
      if (a.getStateAbbre()[0] == b.getStateAbbre()[0]) {
        return byEnName(a, b);
      } else {
        return a.getStateAbbre()[0] < b.getStateAbbre()[0] ? -1 : 1;
      }
    }
    function byRegion(a: Park, b: Park) {
      let regions = new Map([
        ["EA", 0],
        ["MT", 1],
        ["SW", 2],
        ["WE", 3],
        ["PA", 4],
      ]);
      if (a.getRegionAbbre() == b.getRegionAbbre()) {
        return byEnName(a, b);
      } else {
        return regions.get(a.getRegionAbbre()) - regions.get(b.getRegionAbbre());
      }
    }
    function byEstablished(a: Park, b: Park) {
      return a.getEstablishedOrder() - b.getEstablishedOrder();
    }
    var sortAlgo = new Map();
    sortAlgo.set(MenuSort.VISITED, byVisited);
    sortAlgo.set(MenuSort.ESTABLISHED, byEstablished);
    sortAlgo.set(MenuSort.NAME, byEnName);
    sortAlgo.set(MenuSort.STATE, byState);
    sortAlgo.set(MenuSort.REGION, byRegion);

    if (sortOrder == MenuSortOrder.DEFAULT) {
      return Array.from( this.usParks.values() ).sort( sortAlgo.get(sort) );
    } else {
      if (sort == MenuSort.VISITED) {
        return Array.from( this.usParks.values() ).sort( byVisitedReverse );
      } else {
        return Array.from( this.usParks.values() ).sort( sortAlgo.get(sort) ).reverse();
      }
    }
  }

  private sortVisitedTime(parks) {
    var visitedTimeArray = new Array();
    var visitedTimeMap = new Map();

    for (var park in parks) {
      let visitedTime = parks[park]['visited_time'];
      if (visitedTime == "") {
        visitedTimeMap.set(park, 0);
      } else {
        let startEndDate = visitedTime.split(',');
        visitedTimeArray.push([park, startEndDate[0]]);
      }
    }
    // sort by visited start date
    visitedTimeArray.sort((a, b) => a[1] < b[1] ? -1 : 1);

    visitedTimeArray.forEach((sortedTime, index) => {
      visitedTimeMap.set(sortedTime[0], index + 1);
    });
    return visitedTimeMap;
  }
}

export class Park {
  parkUrl: string;
  ch:      string;
  en:      string;
  states:  string[];
  npsUrl:  string;
  region:  string;
  visited: boolean;
  establishedTime: string;
  visitedTime: string;
  establishedOrder: number;
  visitedOrder: number;
  videoId: string[];
  lat: number;
  lon: number;

  stateList: States;
  regionList: Regions;

  constructor(
      parkUrl: string = '',
      ch: string = '',
      en: string = '',
      states: string[] = [],
      npsUrl: string = '',
      region: string = '',
      visited: boolean = false,
      establishedTime: string = '',
      visitedTime: string = '',
      establishedOrder = 0,
      videoId = [],
      lat = undefined,
      lon = undefined,
      visitedOrder = 0) {
    this.parkUrl = parkUrl;
    this.ch = ch;
    this.en = en;
    this.states = states;
    this.npsUrl = npsUrl;
    this.region = region;
    this.visited = visited;
    this.establishedTime = establishedTime;
    this.visitedTime = visitedTime;
    this.establishedOrder = establishedOrder;
    this.visitedOrder = visitedOrder;
    this.videoId = videoId;
    this.stateList = new States();
    this.regionList = new Regions();
    this.lat = lat;
    this.lon = lon;
  }

  getParkUrl() {
    return this.parkUrl;
  }

  getNameShortCh() {
    return this.ch;
  }

  getNameCh() {
    return `${this.ch}國家公園`;
  }

  getNameShortEn() {
    return this.en;
  }

  getNameEn() {
    if (this.parkUrl == 'american-samoa') {
      return `National Park of ${this.en}`;
    } else {
      return `${this.en} National Park`;
    }
  }

  getRegionAbbre() {
    return this.region;
  }

  getRegionCh() {
    return `${this.regionList.getNameCh(this.region)}區`;
  }

  getRegionEn() {
    return this.regionList.getNameEn(this.region);
  }

  getStateNumberArray(): number[] {
    return Array.from({length: this.states.length}, (v, i) => i);
  }

  getStateAbbre(): string[] {
    return this.states;
  }

  getStateCh(): string[] {
    return this.states.map(state => {
      if (state == 'VI' || state == 'AS') {
        return this.stateList.getNameCh(state);
      } else {
        return `${this.stateList.getNameCh(state)}州`;
      }
    });
  }

  getStateEn(): string[] {
    return this.states.map(state => this.stateList.getNameEn(state));
  }

  getNpsUrl() {
    return `https://www.nps.gov/${this.npsUrl}/index.htm`;
  }

  getVisited() {
    return this.visited;
  }

  getThumbnail() {
    if (this.visited) {
      return `/assets/parks/${this.parkUrl}/thumbnail.jpg`
    } else {
      return "/assets/img/my-big-head.png"
    }
  }

  getEstablishedTime() {
    return this.timeStringToCh(this.establishedTime);
  }

  getVisitedTime() {
    if (!this.visited) {
      return "";
    } else {
      let startEndDate = this.visitedTime.split(",");
      if (startEndDate[0] == startEndDate[1]) {
        return this.timeStringToCh(startEndDate[0]);
      } else {
        return `${this.timeStringToCh(startEndDate[0])}-${this.timeStringToCh(startEndDate[1])}`;
      }
    }
  }

  getEstablishedOrder() {
    return this.establishedOrder;
  }

  getVisitedOrder() {
    return this.visitedOrder;
  }

  getVideoId() {
    return this.videoId;
  }

  getLat() {
    return this.lat;
  }

  getLon() {
    return this.lon;
  }

  private timeStringToCh(timeStamp: string) {
    if (timeStamp == "") {
      return '年月日';
    } else {
      var times = timeStamp.split('-');
      var month = times[1][0] == '0' ? times[1].substring(1) : times[1];
      var day = times[2][0] == '0' ? times[2].substring(1) : times[2];
      return `${times[0]}年${month}月${day}日`;
    }
  }
}

interface StateDetails {
  abbre: string;
  ch: string;
  en: string;
}

class States {
  usStates: Map<string, StateDetails>;

  constructor() {
    this.usStates = new Map<string, StateDetails>();
    let states = usStates['default'];
    for (var state in states) {
      let stateDetails: StateDetails = {
        abbre: state,
        ch: states[state]['ch'],
        en: states[state]['en']
      };
      this.usStates.set(state, stateDetails);
    }
  }

  getAbbre(name: string) {
    return this.usStates.get(name).abbre;
  }

  getNameCh(name: string) {
    return this.usStates.get(name).ch;
  }

  getNameEn(name: string) {
    return this.usStates.get(name).en;
  }
}

interface RegionDetails {
  abbre: string;
  ch: string;
  en: string;
}

class Regions {
  usRegions: Map<string, RegionDetails>;

  constructor() {
    this.usRegions = new Map<string, RegionDetails>();
    let regions = usRegions['default'];
    for (var region in regions) {
      let regionDetails: RegionDetails = {
        abbre: region,
        ch: regions[region]['ch'],
        en: regions[region]['en']
      };
      this.usRegions.set(region, regionDetails);
    }
  }

  getAbbre(name: string) {
    return this.usRegions.get(name).abbre;
  }

  getNameCh(name: string) {
    return this.usRegions.get(name).ch;
  }

  getNameEn(name: string) {
    return this.usRegions.get(name).en;
  }
}
