<div class="bg">
  <div class="center">
    <h5 class="name">
      <strong>
      美國國家公園
      </strong>
    </h5>
    <h5 class="subtitle">
      <strong>一起走遍所有公園吧！</strong>
    </h5>
  </div>
</div>
<div class="container">
  <div class="menu-top">
    <h2>總共有{{ numbersOfParks }}個國家公園</h2>
    <p>點選不同國家公園查看遊記與旅遊影片</p>
    <div class="menu-button">
      <span>顯示方式：</span>
      <button
        type="button"
        class="btn btn-sm"
        [ngClass]="{
          'btn-primary' : style == menuStyle.GRID,
          'btn-secondary' : style != menuStyle.GRID
        }"
        (click)="changeMenuStyle(menuStyle.GRID)"
      >格狀</button>
      <button
        type="button"
        class="btn btn-sm"
        [ngClass]="{
          'btn-primary' : style == menuStyle.TABLE,
          'btn-secondary' : style != menuStyle.TABLE
        }"
        (click)="changeMenuStyle(menuStyle.TABLE)"
      >列表</button>
    </div>
    <div class="menu-button">
      <span>排序方式：</span>
      <button
        type="button"
        class="btn btn-sm"
        [ngClass]="{
          'btn-primary' : sort == menuSort.VISITED,
          'btn-secondary' : sort != menuSort.VISITED
        }"
        (click)="changeMenuSort(menuSort.VISITED)"
      >造訪順序</button>
      <button
        type="button"
        class="btn btn-sm"
        [ngClass]="{
          'btn-primary' : sort == menuSort.ESTABLISHED,
          'btn-secondary' : sort != menuSort.ESTABLISHED
        }"
        (click)="changeMenuSort(menuSort.ESTABLISHED)"
      >成立順序</button>
      <button
        type="button"
        class="btn btn-sm"
        [ngClass]="{
          'btn-primary' : sort == menuSort.NAME,
          'btn-secondary' : sort != menuSort.NAME
        }"
        (click)="changeMenuSort(menuSort.NAME)"
      >英文公園名</button>
      <button
        type="button"
        class="btn btn-sm"
        [ngClass]="{
          'btn-primary' : sort == menuSort.STATE,
          'btn-secondary' : sort != menuSort.STATE
        }"
        (click)="changeMenuSort(menuSort.STATE)"
      >英文州名</button>
      <button
        type="button"
        class="btn btn-sm"
        [ngClass]="{
          'btn-primary' : sort == menuSort.REGION,
          'btn-secondary' : sort != menuSort.REGION
        }"
        (click)="changeMenuSort(menuSort.REGION)"
      >英文區域名</button>
    </div>
    <div class="menu-button">
      <span>排序順逆：</span>
      <button
        type="button"
        class="btn btn-sm"
        [ngClass]="{
          'btn-primary' : sortOrder == menuSortOrder.DEFAULT,
          'btn-secondary' : sortOrder != menuSortOrder.DEFAULT
        }"
        (click)="changeMenuSortOrder(menuSortOrder.DEFAULT)"
      >{{ orderDefault }}</button>
      <button
        type="button"
        class="btn btn-sm"
        [ngClass]="{
          'btn-primary' : sortOrder == menuSortOrder.REVERSE,
          'btn-secondary' : sortOrder != menuSortOrder.REVERSE
        }"
        (click)="changeMenuSortOrder(menuSortOrder.REVERSE)"
      >{{ orderReverse }}</button>
    </div>
  </div>
  <app-np-menu
    [style]="style"
    [sort]="sort"
    [sortOrder]="sortOrder"
  ></app-np-menu>
</div>
