import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ParkService } from 'src/app/park.service';

@Component({
  selector: 'app-np-blog',
  templateUrl: './np-blog.component.html',
  styleUrls: ['./np-blog.component.scss']
})
export class NpBlogComponent implements OnInit {
  parkUrl: string;
  npsUrl: string;
  isVisited: boolean;

  constructor(private parkService: ParkService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.parkUrl = this.route.snapshot.params['name'];
    this.npsUrl = this.parkService.getPark(this.parkUrl).getNpsUrl();
    this.isVisited = this.parkService.getPark(this.parkUrl).getVisited();
  }
}
