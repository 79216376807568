<div class="container padding-top">
  <div class="row">
    <app-np-title></app-np-title>
  </div>
</div>
<section class="timeline-section">
  <div class="container">
    <div class="row">
      <app-np-timeline></app-np-timeline>
    </div>
  </div>
</section>
<section class="map-section">
  <div class="container">
    <div class="row">
      <app-np-map></app-np-map>
    </div>
  </div>
</section>
<section class="video-section">
  <div class="container">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" id="Layer_1" x="0px" y="0px" viewBox="0 0 492 110" enable-background="new 0 0 492 110" xml:space="preserve">
      <g>
        <g>
          <path fill="#FF0000" d="M154.3,17.5c-1.8-6.7-7.1-12-13.8-13.8c-12.1-3.3-60.8-3.3-60.8-3.3S31,0.5,18.9,3.8    c-6.7,1.8-12,7.1-13.8,13.8C1.9,29.7,1.9,55,1.9,55s0,25.3,3.3,37.5c1.8,6.7,7.1,12,13.8,13.8c12.1,3.3,60.8,3.3,60.8,3.3    s48.7,0,60.8-3.3c6.7-1.8,12-7.1,13.8-13.8c3.3-12.1,3.3-37.5,3.3-37.5S157.6,29.7,154.3,17.5z"/>
          <polygon fill="#FFFFFF" points="64.2,78.4 104.6,55 64.2,31.6   "/>
        </g>
        <g>
          <g>
            <path fill="#282828" d="M227.9,99.7c-3.1-2.1-5.3-5.3-6.6-9.7c-1.3-4.4-1.9-10.2-1.9-17.5v-9.9c0-7.3,0.7-13.3,2.2-17.7     c1.5-4.5,3.8-7.7,7-9.7c3.2-2,7.3-3.1,12.4-3.1c5,0,9.1,1,12.1,3.1c3,2.1,5.3,5.3,6.7,9.7c1.4,4.4,2.1,10.3,2.1,17.6v9.9     c0,7.3-0.7,13.1-2.1,17.5c-1.4,4.4-3.6,7.6-6.7,9.7c-3.1,2-7.3,3.1-12.5,3.1C235.2,102.8,231,101.7,227.9,99.7z M245.2,89     c0.9-2.2,1.3-5.9,1.3-10.9V56.8c0-4.9-0.4-8.5-1.3-10.7c-0.9-2.3-2.4-3.4-4.5-3.4c-2.1,0-3.5,1.1-4.4,3.4     c-0.9,2.3-1.3,5.8-1.3,10.7v21.3c0,5,0.4,8.7,1.2,10.9c0.8,2.2,2.3,3.3,4.5,3.3C242.8,92.3,244.3,91.2,245.2,89z"/>
            <path fill="#282828" d="M464.4,72.7v3.5c0,4.4,0.1,7.7,0.4,9.9c0.3,2.2,0.8,3.8,1.6,4.8c0.8,1,2.1,1.5,3.8,1.5     c2.3,0,3.9-0.9,4.7-2.7c0.9-1.8,1.3-4.8,1.4-8.9l13.3,0.8c0.1,0.6,0.1,1.4,0.1,2.4c0,6.3-1.7,11-5.2,14.1     c-3.5,3.1-8.3,4.7-14.6,4.7c-7.6,0-12.9-2.4-15.9-7.1c-3-4.7-4.6-12.1-4.6-22V61.6c0-10.2,1.6-17.7,4.7-22.4     c3.2-4.7,8.6-7.1,16.2-7.1c5.3,0,9.3,1,12.1,2.9c2.8,1.9,4.8,4.9,6,9c1.2,4.1,1.7,9.7,1.7,16.9v11.7H464.4z M466.4,43.9     c-0.8,1-1.3,2.5-1.6,4.7c-0.3,2.2-0.4,5.5-0.4,10v4.9h11.2v-4.9c0-4.4-0.1-7.7-0.4-10c-0.3-2.3-0.8-3.9-1.6-4.8     c-0.8-0.9-2-1.4-3.6-1.4C468.3,42.5,467.1,43,466.4,43.9z"/>
            <path fill="#282828" d="M190.5,71.4L173,8.2h15.3l6.1,28.6c1.6,7.1,2.7,13.1,3.5,18h0.4c0.5-3.6,1.7-9.5,3.5-17.9l6.3-28.7h15.3     l-17.7,63.1v30.3h-15.1V71.4z"/>
            <path fill="#282828" d="M311.5,33.4v68.3h-12l-1.3-8.4h-0.3c-3.3,6.3-8.2,9.5-14.7,9.5c-4.5,0-7.9-1.5-10-4.5     c-2.2-3-3.2-7.6-3.2-13.9v-51h15.4v50.1c0,3,0.3,5.2,1,6.5c0.7,1.3,1.8,1.9,3.3,1.9c1.3,0,2.6-0.4,3.8-1.2     c1.2-0.8,2.1-1.9,2.7-3.1V33.4H311.5z"/>
            <path fill="#282828" d="M390.4,33.4v68.3h-12l-1.3-8.4h-0.3c-3.3,6.3-8.2,9.5-14.7,9.5c-4.5,0-7.9-1.5-10-4.5     c-2.2-3-3.2-7.6-3.2-13.9v-51h15.4v50.1c0,3,0.3,5.2,1,6.5c0.7,1.3,1.8,1.9,3.3,1.9c1.3,0,2.6-0.4,3.8-1.2     c1.2-0.8,2.1-1.9,2.7-3.1V33.4H390.4z"/>
            <path fill="#282828" d="M353.3,20.6H338v81.1h-15V20.6h-15.3V8.2h45.5V20.6z"/>
            <path fill="#282828" d="M441.2,44.3c-0.9-4.3-2.4-7.4-4.5-9.4c-2.1-1.9-4.9-2.9-8.6-2.9c-2.8,0-5.5,0.8-7.9,2.4     c-2.5,1.6-4.3,3.7-5.7,6.3h-0.1l0-36h-14.8v96.9h12.7l1.6-6.5h0.3c1.2,2.3,3,4.1,5.3,5.5c2.4,1.3,5,2,7.9,2c5.2,0,9-2.4,11.5-7.2     c2.4-4.8,3.7-12.3,3.7-22.4V62.2C442.6,54.6,442.1,48.6,441.2,44.3z M427.1,72.2c0,5-0.2,8.9-0.6,11.7c-0.4,2.8-1.1,4.8-2.1,6     c-1,1.2-2.3,1.8-3.9,1.8c-1.3,0-2.4-0.3-3.5-0.9c-1.1-0.6-1.9-1.5-2.6-2.7V49.3c0.5-1.9,1.4-3.4,2.7-4.6c1.3-1.2,2.6-1.8,4.1-1.8     c1.6,0,2.8,0.6,3.6,1.8c0.9,1.2,1.4,3.3,1.8,6.2c0.3,2.9,0.5,7,0.5,12.4V72.2z"/>
          </g>
        </g>
      </g>
    </svg>
    <div class="row">
      <div *ngFor="let id of videoId; let i = index" class="col-lg-4 card border-0 video-container">
        <app-np-videos
          [videoId]="id"
          [parkUrl]="parkUrl"
          [idx]="i"
        ></app-np-videos>
      </div>
      <div *ngIf="videoId.length == 0" class="col-lg-12">
        <h5 class="no-video">尚無旅遊影片喔！</h5>
      </div>
    </div>
  </div>
</section>
<section class="blog-section">
  <div class="container padding-bottom">
    <div class="row">
      <app-np-blog></app-np-blog>
    </div>
  </div>
</section>
