import { Component, OnInit, DoCheck } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  name: string;
  navbarOpen: boolean;
  categoriesLink: string[];
  categories: string[];
  whiteLink: boolean;

  constructor(private router: Router) {
    this.name = 'Travel Kevin BKH 旅遊凱文';
    this.navbarOpen = false;
    this.categoriesLink = [
      '',
      'us-national-parks',
      'world-travel',
      'photography',
      'about'
    ];
    this.categories = [
      '首頁',
      '國家公園',
      '世界旅遊',
      '攝影作品',
      '關於'
    ];
  }

  ngOnInit() {
    this.whiteLink = this.router.url == '/' ||
    this.router.url == '/about' ||
    this.router.url == '/us-national-parks';
  }

  ngDoCheck() {
    this.whiteLink = this.router.url == '/' ||
    this.router.url == '/about' ||
    this.router.url == '/us-national-parks';
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  closeNavbar() {
    this.navbarOpen = false;
  }

  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 20); // how far to scroll on each step
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 5);
  }

}
