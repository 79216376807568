<section class="timeline">
  <ol>
    <li>
      <div>
        <h5>成立時間</h5>
        <p class="visited-time">{{ establishedTime }}</p>
        <p>第{{ establishedOrder }}個成立的國家公園</p>
      </div>
    </li>
    <li>
      <div>
        <h5>我的造訪時間</h5>
        <p class="visited-time" *ngIf="!isVisited">我尚未拜訪喔！</p>
        <p class="visited-time" *ngIf="isVisited">{{ visitedTime }}</p>
        <p *ngIf="isVisited">我第{{ visitedOrder }}個造訪的國家公園</p>
      </div>
    </li>
    <li>
      <div>
        <h5>今天的時間</h5>
        <p class="visited-time">{{ todayTime }}</p>
        <p>你準備好出發了嗎？</p>
      </div>
    </li>
    <li></li>
  </ol>
</section>
