import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-np-video',
  templateUrl: './np-video-dialog.component.html',
  styleUrls: ['./np-video-dialog.component.scss']
})
export class NpVideoDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {videoId: string}) { }

  ngOnInit(): void {
    const tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    document.body.appendChild(tag);
  }

}
