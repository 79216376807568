<h5 class="card-title">所在地</h5>
<h5
  class="card-subtitle mb-2 text-muted"
  *ngFor="let idx of stateNumberArray">
  {{ stateArrayCh[idx] }}
  <span>{{ stateArrayEn[idx] }}</span>
</h5>
<h5>座標 {{ latString }} {{ lonString }}</h5>
<div class="us-map-container"></div>

