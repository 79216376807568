import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ParkService } from 'src/app/park.service';

@Component({
  selector: 'app-np-timeline',
  templateUrl: './np-timeline.component.html',
  styleUrls: ['./np-timeline.component.scss']
})
export class NpTimelineComponent implements OnInit {
  parkUrl: string;
  establishedTime: string;
  visitedTime: string;
  todayTime: string;
  establishedOrder: number;
  visitedOrder: number;
  isVisited: boolean;

  constructor(private parkService: ParkService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.parkUrl = this.route.snapshot.params['name'];
    this.establishedTime = this.parkService.getPark(this.parkUrl).getEstablishedTime();
    this.visitedTime = this.parkService.getPark(this.parkUrl).getVisitedTime();
    this.establishedOrder = this.parkService.getPark(this.parkUrl).getEstablishedOrder();
    this.visitedOrder = this.parkService.getPark(this.parkUrl).getVisitedOrder();
    this.isVisited = this.parkService.getPark(this.parkUrl).getVisited();

    let today: Date = new Date();
    this.todayTime = `${today.getFullYear()}年${today.getMonth()+1}月${today.getDate()}日`
  }

}
