<div
  (mouseenter)="mouseEnter()"
  (mouseleave)="mouseLeave()"
  (click)="openDialog()"
  class="shadow">
  <img
    class="card-img-top"
    [src]="videoPath"
    alt="Card image cap">
  <div class="play-button">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px"
      viewBox="-25 -25 100 100"
      class="svg-box">
      <path
        [@rotate]="buttonState"
        class="polygon"
        d="M42.7,42.7L25,50L7.3,42.7L0,25L7.3,7.3L25,0l17.7,7.3L50,25L42.7,42.7z"
      ></path>
      <polygon
        points="32.5,25 21.5,31.4 21.5,18.6"
        class="play-icon"
      ></polygon>
    </svg>
  </div>
  <h2
    [@fadein]="buttonState"
    class="description"
  >{{ day }}</h2>
</div>
