import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[scroller]'
})
export class ScrollerDirective {

    constructor(private elementRef: ElementRef,
                private renderer: Renderer2) { }

    @HostListener('window:scroll', ['$event']) 
    doSomething(event) {
      if (window.scrollY > 200) {
        this.renderer.setStyle(this.elementRef.nativeElement, 'background', '#758184');
      } else {
        this.renderer.setStyle(this.elementRef.nativeElement, 'background', 'transparent');
      }
    }

}
