import { Component, OnInit, Input } from '@angular/core';
import { ParkService, Park, MenuSort, MenuSortOrder, MenuStyle } from 'src/app/park.service';

@Component({
  selector: 'app-np-menu',
  templateUrl: './np-menu.component.html',
  styleUrls: ['./np-menu.component.scss']
})
export class NpMenuComponent implements OnInit {
  @Input() sort: MenuSort;
  @Input() sortOrder: MenuSortOrder;
  @Input() style: MenuStyle;
  menuSort = MenuSort;
  menuStyle = MenuStyle;
  sortedStates: string[];
  sortedStatesMap: Map<string, ParkGroup>;
  sortedRegions: string[];
  sortedRegionsMap: Map<string, ParkGroup>;

  constructor(private parkService: ParkService) {
    this.sortedStates = new Array<string>();
    this.sortedStatesMap = new Map<string, ParkGroup>();
    this.sortedRegions = new Array<string>();
    this.sortedRegionsMap = new Map<string, ParkGroup>();
  }

  ngOnInit() {
    let parksSortedByStates = this.parkService.getParks(MenuSort.STATE, MenuSortOrder.DEFAULT);
    parksSortedByStates.forEach(park => {
      let state = park.getStateAbbre()[0];
      if (!this.sortedStates.includes(state)) {
        this.sortedStates.push(state);
        this.sortedStatesMap.set(state, new ParkGroup(park.getStateEn()[0], park.getStateCh()[0]));
      }
      this.sortedStatesMap.get(state).addPark(park);
    });

    let parksSortedByRegions = this.parkService.getParks(MenuSort.REGION, MenuSortOrder.DEFAULT);
    parksSortedByRegions.forEach(park => {
      let region = park.getRegionAbbre();
      if (!this.sortedRegions.includes(region)) {
        this.sortedRegions.push(region);
        this.sortedRegionsMap.set(region, new ParkGroup(park.getRegionEn(), park.getRegionCh()));
      }
      this.sortedRegionsMap.get(region).addPark(park);
    });
  }

  getParkGroup() {
    if (this.sort == this.menuSort.STATE) {
      if (this.sortOrder == MenuSortOrder.DEFAULT) {
        return this.sortedStates;
      } else {
        return this.sortedStates.reverse();
      }
    } else {
      if (this.sortOrder == MenuSortOrder.DEFAULT) {
        return this.sortedRegions;
      } else {
        return this.sortedRegions.reverse();
      }
    }
  }

  getParkGroupMap() {
    if (this.sort == this.menuSort.STATE) {
      return this.sortedStatesMap;
    } else {
      return this.sortedRegionsMap;
    }
  }
}

class ParkGroup {
  parks: Park[];
  parkNumber: number;
  nameEn: string;
  nameCh: string;

  constructor(nameEn: string, nameCh: string) {
    this.parks = [];
    this.parkNumber = 0;
    this.nameEn = nameEn;
    this.nameCh = nameCh;
  }

  addPark(park: Park) {
    this.parks.push(park);
  }

  getParks() {
    return this.parks;
  }

  getParkNumber() {
    return this.parks.length;
  }

  getNameEn() {
    return this.nameEn;
  }

  getNameCh() {
    return this.nameCh;
  }
}
