import { Component, OnInit } from '@angular/core';
import { ParkService, MenuStyle, MenuSort, MenuSortOrder } from 'src/app/park.service';

@Component({
  selector: 'app-np',
  templateUrl: './np.component.html',
  styleUrls: ['./np.component.scss']
})
export class NpComponent implements OnInit {
  menuStyle = MenuStyle;
  menuSort = MenuSort;
  menuSortOrder = MenuSortOrder;
  style: MenuStyle;
  sort: MenuSort;
  sortOrder: MenuSortOrder;
  numbersOfParks: number;

  orderDefault: string;
  orderReverse: string;

  constructor(private parkService: ParkService) {}

  ngOnInit() {
    this.style = this.menuStyle.GRID;
    this.sort = this.menuSort.VISITED;
    this.sortOrder = this.menuSortOrder.DEFAULT;
    this.numbersOfParks = this.parkService.getLength()
    this.setOrderOption();
  }

  changeMenuStyle(clickedStyle: MenuStyle) {
    this.style = clickedStyle
  }

  changeMenuSort(clickedSort: MenuSort) {
    this.sort = clickedSort;
    this.setOrderOption();
  }

  changeMenuSortOrder(clickedSortOrder: MenuSortOrder) {
    this.sortOrder = clickedSortOrder;
  }

  setOrderOption() {
    let options = new Map([
      [MenuSort.VISITED, new Map([
        [MenuSortOrder.DEFAULT, "由舊到新"],
        [MenuSortOrder.REVERSE, "由新到舊"]
      ])],
      [MenuSort.ESTABLISHED, new Map([
        [MenuSortOrder.DEFAULT, "由舊到新"],
        [MenuSortOrder.REVERSE, "由新到舊"]
      ])],
      [MenuSort.NAME, new Map([
        [MenuSortOrder.DEFAULT, "由A到Z"],
        [MenuSortOrder.REVERSE, "由Z到A"]
      ])],
      [MenuSort.STATE, new Map([
        [MenuSortOrder.DEFAULT, "由A到Z"],
        [MenuSortOrder.REVERSE, "由Z到A"]
      ])],
      [MenuSort.REGION, new Map([
        [MenuSortOrder.DEFAULT, "由東到西"],
        [MenuSortOrder.REVERSE, "由西到東"]
      ])]
    ])
    this.orderDefault = options.get(this.sort).get(MenuSortOrder.DEFAULT);
    this.orderReverse = options.get(this.sort).get(MenuSortOrder.REVERSE);
  }

}
