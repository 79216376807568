<h1 mat-dialog-title>歡迎觀看YouTube影片</h1>
<p mat-dialog-content>別忘了訂閱我的頻道</p>
<hr>
<div mat-dialog-content>
  <youtube-player
    [videoId]="data.videoId"
    suggestedQuality="highres"
  ></youtube-player>
</div>
<div mat-dialog-actions align="end">
   <button mat-raised-button color="warn" mat-dialog-close>關閉</button>
</div>
