import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NpGridComponent } from './np/np-menu/np-grid/np-grid.component';
import { NpComponent } from './np/np.component';
import { NpTitleComponent } from './np/np-content/np-title/np-title.component';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NpTableComponent } from './np/np-menu/np-table/np-table.component';
import { NpContentComponent } from './np/np-content/np-content.component';
import { AboutComponent } from './about/about.component';
import { PgComponent } from './pg/pg.component';
import { ScrollerDirective } from './scroller.directive';
import { ScrollToTopDirective } from './scroll-to-top.directive';
import { WorldComponent } from './world/world.component';
import { ParkService } from './park.service';
import { NpMenuComponent } from './np/np-menu/np-menu.component';
import { YouTubePlayerModule } from "@angular/youtube-player";
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NpVideoDialogComponent } from './np/np-content/np-video-dialog/np-video-dialog.component';
import { NpVideosComponent } from './np/np-content/np-videos/np-videos.component';
import { NpMapComponent } from './np/np-content/np-map/np-map.component';
import { NpTimelineComponent } from './np/np-content/np-timeline/np-timeline.component';
import { NpBlogComponent } from './np/np-content/np-blog/np-blog.component';
import { NpContentGuard } from './np/np-content/np-content.guard';

const appRoutes: Routes = [
  {'path': '', component: HomeComponent},
  {'path': 'us-national-parks', component: NpComponent},
  {'path': 'us-national-parks/:name', component: NpContentComponent, canActivate: [NpContentGuard]},
  {'path': 'world-travel', component: WorldComponent},
  {'path': 'photography', component: PgComponent},
  {'path': 'about', component: AboutComponent},
  {'path': 'not-found', component: NotFoundComponent},
  {'path': '**', redirectTo: '/not-found'},
]

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    NpGridComponent,
    NpComponent,
    NpTitleComponent,
    HomeComponent,
    NotFoundComponent,
    NpTableComponent,
    NpContentComponent,
    AboutComponent,
    PgComponent,
    ScrollerDirective,
    ScrollToTopDirective,
    WorldComponent,
    NpMenuComponent,
    NpVideoDialogComponent,
    NpVideosComponent,
    NpMapComponent,
    NpTimelineComponent,
    NpBlogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot(appRoutes),
    BrowserAnimationsModule,
    YouTubePlayerModule,
    MatDialogModule,
    MatButtonModule,
  ],
  providers: [ParkService, NpContentGuard],
  bootstrap: [AppComponent],
  entryComponents: [NpVideoDialogComponent],
})
export class AppModule { }
