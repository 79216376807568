import { Component, OnInit, Input } from '@angular/core';
import { Park, ParkService, MenuSort, MenuSortOrder } from 'src/app/park.service';

@Component({
  selector: 'app-np-table',
  templateUrl: './np-table.component.html',
  styleUrls: ['./np-table.component.scss']
})
export class NpTableComponent implements OnInit {
  @Input() sort: MenuSort;
  @Input() sortOrder: MenuSortOrder;
  @Input() parks: Park[];

  constructor(private parkService: ParkService) {}

  ngOnInit() {}

}
