import { Component, OnInit, Input } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { NpVideoDialogComponent } from '../np-video-dialog/np-video-dialog.component';

@Component({
  selector: 'app-np-videos',
  templateUrl: './np-videos.component.html',
  styleUrls: ['./np-videos.component.scss'],
  animations: [
    trigger('rotate', [
      state('normal', style({
        transform: 'rotate(0deg) scale(1)',
        filter: 'brightness(1)'
      })),
      state('hover', style({
        transform: 'rotate(90deg) scale(1.2)',
        filter: 'brightness(0.5)'
      })),
      transition('normal => hover', animate('400ms cubic-bezier(0.7, 0.01, 0.37, 1)')),
      transition('hover => normal', animate('400ms cubic-bezier(0.7, 0.01, 0.37, 1)'))
    ]),

    trigger('fadein', [
      state('normal', style({
        transform: 'translateX(25px)',
        opacity: 0
      })),
      state('hover', style({
        transform: 'translateX(0px)',
        opacity: 1
      })),
      transition('normal => hover', animate('300ms cubic-bezier(0.7, 0.01, 0.37, 1)')),
      transition('hover => normal', animate('300ms cubic-bezier(0.7, 0.01, 0.37, 1)'))
    ])
  ]
})
export class NpVideosComponent implements OnInit {
  @Input() videoId: string;
  @Input() parkUrl: string;
  @Input() idx: number;
  videoPath: string;
  buttonState: string;
  descState: string;
  dayMap: Map<number, string>;
  day: string;

  constructor(public dialog: MatDialog) {
    this.buttonState = 'normal';
    this.descState = 'normal';
    this.dayMap = new Map([
      [0, '一'],
      [1, '二'],
      [2, '三']
    ]);
  }

  ngOnInit(): void {
    this.videoPath = `/assets/parks/${this.parkUrl}/video/${this.videoId}.png`;
    this.day = `第${this.dayMap.get(this.idx)}天`;
  }

  mouseEnter() {
    this.buttonState = 'hover';
    this.descState = 'hover';
  }

  mouseLeave() {
    this.buttonState = 'normal';
    this.descState = 'normal';
  }

  openDialog() {
    const dialogRef = this.dialog.open(NpVideoDialogComponent, {data: {videoId: this.videoId}});

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
