import { Component, OnInit } from '@angular/core';
import { ParkService } from 'src/app/park.service';
import { ActivatedRoute } from '@angular/router';
import * as d3 from 'd3';
import * as states from '../../../../assets/map/us-states-geojson.json';

@Component({
  selector: 'app-np-map',
  templateUrl: './np-map.component.html',
  styleUrls: ['./np-map.component.scss']
})
export class NpMapComponent implements OnInit {
  parkUrl: string;
  nameCh: string;
  lat: number;
  lon: number;
  latString: string;
  lonString: string;
  stateNumberArray: number[];
  stateArrayCh: string[];
  stateArrayEn: string[];
  stateEn: string[];

  constructor(private parkService: ParkService,
              private route: ActivatedRoute) {
    this.parkUrl = this.route.snapshot.params['name'];
    this.nameCh = this.parkService.getPark(this.parkUrl).getNameCh()
    this.lat = this.parkService.getPark(this.parkUrl).getLat();
    this.lon = this.parkService.getPark(this.parkUrl).getLon();
    this.stateNumberArray = this.parkService.getPark(this.parkUrl).getStateNumberArray();
    this.stateArrayCh = this.parkService.getPark(this.parkUrl).getStateCh();
    this.stateArrayEn = this.parkService.getPark(this.parkUrl).getStateEn();
    const dms = this.convertDMS(this.lat, this.lon);
    this.latString = dms.lat;
    this.lonString = dms.lon;
    this.stateEn = this.parkService.getPark(this.parkUrl).getStateEn();
  }

  ngOnInit(): void {
    //Width and height of map
    const width = 960;
    const height = 500;
    // D3 Projection
    const projection = d3.geoAlbersUsa()
      .translate([width/2, height/2])    // translate to center of screen
      .scale([1000]);          // scale things down so see entire US
    // Define path generator
    const path = d3.geoPath()               // path generator that will convert GeoJSON to SVG paths
      .projection(projection);  // tell path generator to use albersUsa projection
    //Create SVG element and append map to the SVG
    const svg = d3.select(".us-map-container")
      .append("svg")
      .attr("width", width)
      .attr("height", height);
    // Append Div for tooltip to SVG
    const div = d3.select(".us-map-container")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);
    // Bind the data to the SVG and create one path per GeoJSON feature
    svg.selectAll("path")
      .data(states.features)
      .enter()
      .append("path")
      .attr("d", path)
      .style("stroke", "#fff")
      .style("stroke-width", "1")
      .style("fill", (d) => {
        if (this.stateEn.includes(d.properties.name)) {
          return "2c8cc8";
        } else {
          return "c0e0f3";
        }
      });

    const marker = [
      {
        path: 'm12 0c-4.4183 2.3685e-15 -8 3.5817-8 8 0 1.421 0.3816 2.75 1.0312 3.906 0.1079 0.192 0.221 0.381 0.3438 0.563l6.625 11.531 6.625-11.531c0.102-0.151 0.19-0.311 0.281-0.469l0.063-0.094c0.649-1.156 1.031-2.485 1.031-3.906 0-4.4183-3.582-8-8-8zm0 4c2.209 0 4 1.7909 4 4 0 2.209-1.791 4-4 4-2.2091 0-4-1.791-4-4 0-2.2091 1.7909-4 4-4z',
        color: '#e74c3c'
      },
      {
        path: 'm12 3c-2.7614 0-5 2.2386-5 5 0 2.761 2.2386 5 5 5 2.761 0 5-2.239 5-5 0-2.7614-2.239-5-5-5zm0 2c1.657 0 3 1.3431 3 3s-1.343 3-3 3-3-1.3431-3-3 1.343-3 3-3z',
        color: '#c0392b'
      }
    ]
    const markerX = 16;
    const markerY = 24;

    svg.append('g')
      .attr('transform', `translate(${projection([this.lon, this.lat])[0]-markerX/2},${projection([this.lon, this.lat])[1]-markerY})`)
      .selectAll("path")
      .data(marker)
      .enter()
      .append("path")
      .attr('d', function(d) {
        return d.path;
      })
      .attr('fill', function(d) {
        return d.color;
      })
      .on("mouseover", () => {
          div.transition()
              .duration(200)
              .style("opacity", .9);
              div.text(this.nameCh)
              .style("left", (d3.event.pageX + 5) + "px")
              .style("top", (d3.event.pageY - 23) + "px")
              .style("padding", "3px")
              .style("border-radius", "3px")
              .style("background", "#95a2c3");
      })
      .on("mouseout", function(d) {
          div.transition()
            .duration(500)
            .style("opacity", 0);
      });
  }

  private convertDMS(lat: number, lon: number ) {
    const toDMS = (num: number): string => {
      // The sign doesn't matter
      num = Math.abs(num);
      // Get the degrees
      const degree = Math.floor(num);
      // Strip off the answer we already have
      num = num-degree;
      // And then put the minutes before the '.'
      num *= 60;
      // Get the minutes
      const minute = Math.floor(num);
      // Remove them from the answer
      num = num-minute;
      // Put the seconds before the '.'
      num *= 60;
      // Get the seconds
      // Should this be round? Or rounded by special rules?
      const second = Math.floor(num);
      // Put it together.
      return `${degree}°${minute}′${second}″`;
    }
    const northOrSouth = lat > 0 ? "N" : "S";
    const eastOrWest = lon > 0 ? "E" : "W";
    return {lat: toDMS(lat) + northOrSouth, lon: toDMS(lon) + eastOrWest};
  }

}
