<div *ngIf="style == menuStyle.GRID">
  <div *ngIf="sort == menuSort.STATE || sort == menuSort.REGION">
    <div *ngFor="let group of getParkGroup()">
      <div>
        <span class="group-name">{{ getParkGroupMap().get(group).getNameCh() }}{{ getParkGroupMap().get(group).getNameEn() }}</span>
        <span>總共{{ getParkGroupMap().get(group).getParkNumber() }}個國家公園</span>
      </div>
      <div class="row">
        <app-np-grid
          *ngFor="let park of getParkGroupMap().get(group).getParks()"
          class="col-lg-4"
          [parkUrl]="park.getParkUrl()"
        ></app-np-grid>
      </div>
    </div>
  </div>
  <div *ngIf="sort != menuSort.STATE && sort != menuSort.REGION">
    <div class="row">
      <app-np-grid
        *ngFor="let park of parkService.getParks(sort, sortOrder)"
        class="col-lg-4"
        [parkUrl]="park.getParkUrl()"
      ></app-np-grid>
    </div>
  </div>
</div>
<div *ngIf="style == menuStyle.TABLE" class="row">
  <div *ngIf="sort == menuSort.STATE || sort == menuSort.REGION">
    <div *ngFor="let group of getParkGroup()">
      <div>
        <span class="group-name">{{ getParkGroupMap().get(group).getNameCh() }}{{ getParkGroupMap().get(group).getNameEn() }}</span>
        <span>總共{{ getParkGroupMap().get(group).getParkNumber() }}個國家公園</span>
      </div>
      <div>
        <app-np-table
          [sort]="sort"
          [sortOrder]="sortOrder"
          [parks]="getParkGroupMap().get(group).getParks()"
        ></app-np-table>
      </div>
    </div>
  </div>
  <div *ngIf="sort != menuSort.STATE && sort != menuSort.REGION">
    <app-np-table
      [sort]="sort"
      [sortOrder]="sortOrder"
      [parks]="parkService.getParks(sort, sortOrder)"
    ></app-np-table>
  </div>
</div>