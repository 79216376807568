import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ParkService } from 'src/app/park.service';

@Component({
  selector: 'app-np-title',
  templateUrl: './np-title.component.html',
  styleUrls: ['./np-title.component.scss']
})
export class NpTitleComponent implements OnInit {
  parkUrl: string;
  parkNameCh: string;
  parkNameEn: string;

  constructor(private parkService: ParkService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.parkUrl = this.route.snapshot.params['name'];
    this.parkNameCh = this.parkService.getPark(this.parkUrl).getNameCh();
    this.parkNameEn = this.parkService.getPark(this.parkUrl).getNameEn();
  }

}
