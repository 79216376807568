<table class="table table-striped">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr 
      *ngFor="let park of parks; let i = index">
      <th scope="row">{{ i + 1 }}</th>
      <td>
        <img
          [src]="park.getThumbnail()"
          alt="Card image cap">
      </td>
      <td>
        <strong>{{ park.getNameCh() }}</strong>
        <p>{{ park.getNameEn() }}</p>
      </td>
      <td>
        <span *ngFor="let state of park.getStateCh()">
          {{ state }}
        </span>
      </td>
      <td>
        <a class="btn btn-hollow"
          role="button"
          [routerLink]="['/us-national-parks', park.getParkUrl()]">
          前往部落格
        </a>
      </td>
      <td>
        <a class="btn btn-hollow"
          role="button"
          [href]="park.getNpsUrl()"
          target="_blank">
          前往官方網站
        </a>
      </td>
    </tr>
  </tbody>
</table>